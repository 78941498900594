// Generated by Framer (16060ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["yrDfaFUBt", "ZkHjXSSfX", "ookx8VOeG"];

const variantClassNames = {ookx8VOeG: "framer-v-m7kspb", yrDfaFUBt: "framer-v-1amgi0m", ZkHjXSSfX: "framer-v-t03pht"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "yrDfaFUBt", "Tab 2": "ZkHjXSSfX", "Tab 3": "ookx8VOeG"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "yrDfaFUBt", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yrDfaFUBt", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tapzrxt5 = activeVariantCallback(async (...args) => {
setVariant("yrDfaFUBt")
})

const tap10e5ck4 = activeVariantCallback(async (...args) => {
setVariant("ZkHjXSSfX")
})

const tapazbv41 = activeVariantCallback(async (...args) => {
setVariant("ookx8VOeG")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ha6qg", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1amgi0m", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"yrDfaFUBt"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({ookx8VOeG: {"data-framer-name": "Tab 3"}, ZkHjXSSfX: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1gpbek9-container"} layoutDependency={layoutDependency} layoutId={"aFjrIy5Wt-container"} transition={transition}><Tab height={"100%"} id={"aFjrIy5Wt"} layoutId={"aFjrIy5Wt"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({ookx8VOeG: {tap: tapzrxt5, variant: "ym0P2kopY"}, ZkHjXSSfX: {tap: tapzrxt5, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-cxhmna-container"} layoutDependency={layoutDependency} layoutId={"my2LxY8Qd-container"} transition={transition}><Tab height={"100%"} id={"my2LxY8Qd"} layoutId={"my2LxY8Qd"} tap={tap10e5ck4} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({ZkHjXSSfX: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-18yw714-container"} layoutDependency={layoutDependency} layoutId={"xm7JXU61X-container"} transition={transition}><Tab height={"100%"} id={"xm7JXU61X"} layoutId={"xm7JXU61X"} tap={tapazbv41} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({ookx8VOeG: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ha6qg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ha6qg .framer-1hcmgt7 { display: block; }", ".framer-ha6qg .framer-1amgi0m { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-ha6qg .framer-1gpbek9-container, .framer-ha6qg .framer-cxhmna-container, .framer-ha6qg .framer-18yw714-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ha6qg .framer-1amgi0m { gap: 0px; } .framer-ha6qg .framer-1amgi0m > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-ha6qg .framer-1amgi0m > :first-child { margin-left: 0px; } .framer-ha6qg .framer-1amgi0m > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ZkHjXSSfX":{"layout":["auto","auto"]},"ookx8VOeG":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramerMLXwWQnoN: React.ComponentType<Props> = withCSS(Component, css, "framer-ha6qg") as typeof Component;
export default FramerMLXwWQnoN;

FramerMLXwWQnoN.displayName = "Elements/Tabs";

FramerMLXwWQnoN.defaultProps = {height: 49, width: 275};

addPropertyControls(FramerMLXwWQnoN, {variant: {options: ["yrDfaFUBt", "ZkHjXSSfX", "ookx8VOeG"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramerMLXwWQnoN, [...TabFonts])